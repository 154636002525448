import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";
import Image from "../../components/Image";

const DoorStyleColumn: React.FC = ({ children }) => (
  <div className="col-6 col-sm-4 col-md-3 col-lg-2 pb-3">{children}</div>
);

interface DoorStyleCardProps {
  title: string;
}

const DoorStyleCard: React.FC<DoorStyleCardProps> = ({ title }) => {
  const name = "ds_" + title.replace(/ /g, "_").toLowerCase();

  return (
    <div className="card h-100">
      <div className="card-image">
        <Image name={name} />
      </div>
      <div className="card-body px-1 py-2">
        <p className="card-text text-size-smaller">{title}</p>
      </div>
    </div>
  );
};

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Door Styles">
    <div className="container my-5">
      <p className="text-size-medium">
        These are examples of the range of door styles that we can make for you.
        You can choose your own timber, panel profile, frame profile and edge
        finish.
      </p>
    </div>
    <div className="container my-5">
      <div className="row">
        <DoorStyleColumn>
          <DoorStyleCard title="Cambridge with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Cambridge with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regency with Bolection Mould" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regency with Square Shoulder" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regency with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regency with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Solid Panel with Stopped Chamfer" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Solid Panel with V Groove" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Solid Panel with Bevel Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Solid Panel with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Solid Panel with Square Shoulder" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="V Groove with Flush Panel" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="V Groove with Square Shoulder" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="V Groove with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Veneer Panel with V Through Shoulder" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Veneer Panel with Square Shoulder" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Veneer Panel with Bolection Mould" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Cove with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Cove with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Adelaide with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Arched Heritage with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Arched Cove with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Arched Cove with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Heritage with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Double Profile with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Bevel Panel with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Bevel Panel with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regal with Double Profile Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Regal with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Arched Cambridge with OG Frame" />
        </DoorStyleColumn>
        <DoorStyleColumn>
          <DoorStyleCard title="Arched Cambridge with Double Profile Frame" />
        </DoorStyleColumn>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
